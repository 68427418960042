import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import "../../assets/css/Driverlist.scss";
import ic_add from "../../assets/images/Trasnvehicle/ic_add.svg";
import ic_export from "../../assets/images/Trasnvehicle/ic_export.svg";
import ic_options from "../../assets/images/Trasnvehicle/ic_options.svg";
import ic_import from "../../assets/images/Trasnvehicle/ic_excel.svg";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AppContext } from "../../context/user/AppContext";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import WhiteTolltip from "../../sharedComponent/WhiteTolltip";
import * as XLSX from "xlsx";

const DriverList = () => {
  const [selected, setSelected] = useState("All");
  const navigate = useNavigate();
  const { mainClass, customerData,setApiCalling } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [driverList, setDriverList] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  $(document).ready(function () {
    let table;
    if ($.fn.dataTable.isDataTable(".dataList")) {
      table = $(".dataList").DataTable();
    } else {
      table = $(".dataList").DataTable({
        ordering: false,

        language: {
          search: "",
          searchPlaceholder: "Search...",
          sLengthMenu: "Entries per page _MENU_",
        },
      });
    }
    // $('#transporterList').DataTable({
    //   // ordering: false
    // });
  });
  const exportToexcel = () => {
    var excelData = [
      [
        "S.No",
        "Driver Name",
        "Nationality",
        "Email id",
        "Mobile Number",
        "License Number",
      ],
    ];
    driverList.map((elt, index) => {
      excelData.push([
        index + 1,
        elt.driver_name,
        elt.nationality,
        elt.email_id,
        elt.mobile_number,
        elt.licence_number,
      ]);
    });
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "Drivers_list.xlsx");
  };
  const getDriverList = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DRIVER_LIST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setDriverList(res.json.driver_list);
          setOriginalData(res.json.driver_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };
  useEffect(() => {
    $(".dataList").DataTable().destroy();
    if (selected === "All" || selected === "select") {
      setDriverList(originalData);
    } else {
      setDriverList(
        originalData.filter((data) => {
          if (selected === "inactive") return data.vehicle_status === "blocked";
          else return data.driver_status === selected;
        })
      );
    }
  }, [selected, setDriverList, originalData]);
  useEffect(() => {
    return () => {
      setDriverList([]);
    };
  }, []);
  const blockDriver = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_DRIVER,
      JSON.stringify({ ...customerData, driver_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getDriverList();
      }
    });
  };
  const unBlockDriver = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNBLOCK_DRIVER,
      JSON.stringify({ ...customerData, driver_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getDriverList();
      }
    });
  };

  const deleteDriver = (id) => {
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETE_DRIVER,
      JSON.stringify({ ...customerData, driver_id: id })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      setApiCalling(false);
      if (res.json.result) {
        swal(res.json.message);
        $(".dataList").DataTable().destroy();
        getDriverList();
      }
    });
  };

  useEffect(() => {
    getDriverList();
  }, []);
  return (
    <div>
      <div className={`vehicle ${mainClass}`} id="cxMain">
        <div className="d-flex  ">
          <div className="input-group  justify-content-end ">
            <div className="icon-group col-sm-3  mt-2">
              <ul className="d-flex list-unstyled  justify-content-end ">
                <li>
                  <div className="form-outline searchBox  m-0 ">
                    <label htmlFor="select" className="mr-4">
                      Filter
                    </label>
                    <select
                      className="form-select"
                      onChange={(e) => setSelected(e.target.value)}
                    >
                      <option value={"select"}>Select</option>
                      <option value={"All"}>All</option>
                      <option value={"available"}>Available</option>
                      <option value={"Busy"}>Busy</option>
                      <option value={"active"}>Active</option>
                      <option value={"inactive"}>Inactive</option>
                    </select>
                  </div>
                </li>
                {/* <li className="nav-item icons p-2 me-3 " data-tip="Export">
                  <Link to="#">
                    <img
                      src={ic_export}
                      className="add mb-2"
                      alt="send-load-icon"
                    />
                  </Link>
                </li> */}
                <li
                  className="nav-item icons p-2 me-3"
                  data-tip="Export to Excel"
                >
                  <Link to="#" onClick={exportToexcel}>
                    <img src={ic_import} className="add" alt="send-load-icon" />
                  </Link>
                </li>
                <li className="nav-item icons p-2" data-tip="Add Driver">
                  <Link to="/AddDriver">
                    <img src={ic_add} className="add" alt="send-load-icon" />
                  </Link>
                </li>
                
              </ul>
            </div>
          </div>
        </div>

        <div
          className=""
          style={{ width: "100%", overflow: "auto", padding: "30px 0" }}
        >
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <table className=" dataList " id="driverList">
              <thead>
                <tr>
                  <th>
                    <label>S.No</label>
                  </th>
                  <th>
                    <label>Driver Name</label>
                  </th>
                  <th>
                    <label>Nationality</label>
                  </th>
                  <th>
                    <label>Email id</label>
                  </th>
                  <th>
                    <label>Mobile Number</label>
                  </th>
                  <th>
                    <label>License Number</label>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {driverList.map((driver, index) => {
                  return (
                    <tr className="Rowbgcolor p-2" key={"driver" + index}>
                      <td>{index + 1}</td>
                      <td id="DriverNAM">{driver.driver_name}</td>
                      <td id="Nationality">{driver.nationality}</td>
                      <td id="emial">{driver.email_id}</td>
                      <td id="mobileNO">{driver.mobile_number}</td>
                      <td id="LicenseNo">{driver.licence_number}</td>
                      <td>
                        <div className="option-dropdown dropdown p-2">
                          <a
                            href=""
                            className=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={ic_options} alt="" />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button
                                className="dropdown-item"
                                type="button"
                                onClick={() =>
                                  navigate("/AddDriver/" + driver.driver_id)
                                }
                              >
                                Edit{" "}
                                <img
                                  src={dropdown_arrow_blue}
                                  className="arrowvehicle"
                                  alt=""
                                />
                              </button>
                            </li>
                            <li>
                              <Link
                                to={
                                  "/VehicleDriverDetailsHeader/" +
                                  driver.driver_id +
                                  "/true"
                                }
                                className="text-decoration-none"
                                state={{ id: driver.driver_id, driver: true }}
                              >
                                <button className="dropdown-item" type="button">
                                  View Details
                                </button>
                              </Link>
                            </li>
                            {driver.driver_status === "blocked" ? (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() =>
                                    unBlockDriver(driver.driver_id)
                                  }
                                >
                                  Unblock
                                </button>
                              </li>
                            ) : (
                              <li>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => blockDriver(driver.driver_id)}
                                >
                                  Block
                                </button>
                              </li>
                            )}
                            <li>
                              <Link
                                to="#"
                                className="text-decoration-none"
                                onClick={() => deleteDriver(driver.driver_id)}
                              >
                                <button className="dropdown-item" type="button">
                                  Delete
                                </button>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default DriverList;
