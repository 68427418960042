import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { AppContext } from "../context/user/AppContext";
import Loader from "../sharedComponent/Loader";
// import DriverDetails from "../Driver/DriverDetails";
// import VehiclesDetails from "../Vehicles/VehiclesDetails";
// import '../../assets/css/VehicleDriverDetailsHeader.scss'
import $ from "jquery";
import DriverDetails from "../pages/Driver/DriverDetails";
import VehiclesDetails from "../pages/Vehicles/VehiclesDetails";
import OfferLoadDriverDetails from "../pages/Driver/OfferLoadDriverDetails";
import OfferLoadVehiclesDetails from "../pages/Vehicles/OfferLoadVehicleDetails";
const VehicleDriverDetails = () => {
  const params = useParams();

  const [state, setState] = useState({
    ...params,
    vehicle: params.vehicle_id,
    trailer: (params.trailer_id !== 'no-trailer') ? params.trailer_id : '',
    driver: params.driver_id,
  });
  const [loading, setLoading] = useState(false);
  const [VehicleDetails, setVehicleDetails] = useState({});
  const [trailerDetail, setTrailerDetail] = useState({});
  const [owner_details, setOwner_details] = useState({});
  const [driver_details, setDriver_details] = useState([]);
  const { mainClassB, customerData } = useContext(AppContext);
  const [vehicle, setVehicle] = useState(true);
  const [trailer, setTrailer] = useState(false);
  const [driver, setDriver] = useState(false);

  useEffect(() => {
    getVehicleDetails();
    // $('#rowTab a:first').tab('show');
  }, [driver, vehicle]);

  const getVehicleDetails = () => {
    setLoading(true);
    if (driver) {
        //setDriver(false);
      postWithAuthCallWithErrorResponse(
        ApiConfig.DRIVER_DETAILS,
        JSON.stringify({ ...customerData, driver_id: state.driver, vehicle_id:state.vehicle, trailer_id:state.trailer})
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setLoading(false);
          setVehicleDetails(res.json.vehicle_details);
          setDriver_details(res.json.driver_details);
          setOwner_details(res.json.owner_details);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } 
    if (vehicle) {
        //setVehicle(false);
      postWithAuthCallWithErrorResponse(
        ApiConfig.VEHICLE_DETAILS,
        JSON.stringify({ ...customerData, vehicle_id: state.vehicle, driver_id:state.driver, trailer_id: state.trailer })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setLoading(false);
          setVehicleDetails(res.json.vehicle_details);
          setDriver_details(res.json.driver_details);
          setOwner_details(res.json.owner_details);
          setTrailerDetail(res.json.trailer_detail);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  function selectDriver(e) {
    e.preventDefault();
    setDriver(true);
    setVehicle(false);
    getVehicleDetails();
  }

  function selectVehicle(e) {
    e.preventDefault();
    setVehicle(true);
    setDriver(false);
    getVehicleDetails();
  }
  return (
    <div>
      <main className=" ">
        <div
          className={`vehicle-driver-detail-header scroll-style ${mainClassB} `}
          id="cxMainB"
        >
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <>
              <ul
                className="nav nav-pills mb-1 pt-2"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className={`nav-link tab-link ${driver && 'active'}`}
                    id="pills-customerdetails-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-customerdetails"
                    type="button"
                    role="tab"
                    aria-controls="pills-customerdetails"
                    aria-selected="false"
                    onClick = {e => {selectDriver(e)}}
                  >
                    
                    Driver Details
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className={`nav-link tab-link ${!driver && 'active'}`}
                    id="pills-driverdetails-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-driverdetails"
                    type="button"
                    role="tab"
                    aria-controls="pills-driverdetails"
                    aria-selected="true"
                    onClick = {e => {selectVehicle(e)}}
                  >
                    
                    Vehicle Details
                  </Link>
                </li>
              </ul>
              <div
                className="tab-content active"
                id="pills-tabContent"
                style={{ marginBottom: "80px", overflow: "auto" }}
              >
               {vehicle ? <div
                  //className={`tab-pane fade show`}
                  id="pills-driverdetails"
                  role="tabpanel"
                  aria-labelledby="pills-driverdetails-tab"
                >
                  {VehicleDetails?.vehicle_id && (
                    <OfferLoadVehiclesDetails
                      getVehicleDetails={getVehicleDetails}
                      vehicleDeatails={VehicleDetails}
                      ownerDetails={owner_details}
                      trailerDetail={trailerDetail}
                    />
                  )}
                </div> : <></>}

                {driver ? <div
                  //className={`tab-pane fade show`}
                  id="pills-customerdetails"
                  role="tabpanel"
                  aria-labelledby="pills-customerdetails-tab"
                >
                  {(driver_details.driver_id || driver_details.user_id) && (
                    <OfferLoadDriverDetails
                      driverDetails={driver_details}
                      getVehicleDetails={getVehicleDetails}
                      ownerDetails={owner_details}
                    />
                  )}
                </div> : <></>}
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default VehicleDriverDetails;
