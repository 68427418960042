import React, { useContext, useEffect, useState } from "react";
import ic_edit from "../../assets/images/ic_edit (2).svg";

import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import {
  postWithAuthCallWithErrorResponse,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";

export default function EditShipperApproval() {
  const navigate = useNavigate();

  const { customerData } = useContext(AppContext);
  const [shipperPendingList, setShipperPendingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [comment, setComment] = useState("");

  const [action, setAction] = useState(false);

  const getShipperList = () => {
    var uri = ApiConfig.UPDATED_SHIPPER_LIST;
    setLoading(true);
    postWithAuthCallWithErrorResponse(uri, JSON.stringify({ ...customerData }))
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setShipperPendingList(res.json.shipper_list);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const shipperApprove = (id) => {
    simplePostCall(
      ApiConfig.APPROVE_USER,
      JSON.stringify({
        ...customerData,
        pending_user_id: id,
        comments: comment,
      })
    )
      .then((res) => {
        if (res.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        getShipperList();
        handleClose();

        navigate("/ShippersLists/");
        swal(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const shipperReject = (id) => {
    simplePostCall(
      ApiConfig.REJECT_USER,
      JSON.stringify({
        ...customerData,
        pending_user_id: id,
        comments: comment,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        handleClose();
        getShipperList();
        swal(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getShipperList();
  }, []);
  return (
    <React.Fragment>
      <main>
        <div style={{ margin: "20px" }}>
          <div className=" all-request">
            <div className="  ">
              <div className="offerLoadsMainLists">
                <div className="mainLoadTable" style={{ overflow: "auto" }}>
                  <table className="dataList" style={{ minWidth: "900px" }}>
                    <thead>
                      <tr>
                        <th>
                          <label>S.No</label>
                        </th>
                        <th>
                          <label>Company Name</label>
                        </th>
                        <th>
                          <label>Contact Person</label>
                        </th>
                        <th>
                          <label>Business Area</label>
                        </th>

                        <th>
                          <label>Country</label>
                        </th>
                        <th>
                          <label>Status</label>
                        </th>
                        <th
                          className=" text-end "
                          style={{ paddingRight: "50px" }}
                        >
                          <label>Action</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shipperPendingList ? (
                        shipperPendingList &&
                        shipperPendingList.map((shipperList, index) => {
                          return (
                            <tr
                              className="Rowbgcolor"
                              key={"shipperList" + index}
                            >
                              <td>
                                <label>{index + 1}</label>
                              </td>
                              <td>
                                <Link
                                  className="text-decoration-none"
                                  to={"/ShipperDetails/" + shipperList.user_id}
                                  state={shipperList}
                                >
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                      color: "#38847C",
                                      textDecoration: "underline",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {shipperList.company_name}
                                  </label>
                                </Link>
                              </td>
                              <td>
                                <label>{shipperList.contact_person}</label>
                              </td>
                              <td>
                                <label>{shipperList.business_area}</label>
                              </td>

                              <td>
                                <label>{shipperList.user_country}</label>
                              </td>
                              <td>
                                <label>{shipperList.user_status}</label>
                              </td>

                              <td className="text-end">
                                <label>
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      handleShow(setAction(false));
                                    }}
                                    className="regiBTN"
                                    id="accept"
                                  >
                                    Accept
                                    {/* <img src={cross_green} alt="" /> */}
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      handleShow(setAction(true));
                                    }}
                                    className="regiBTN"
                                    id="reject"
                                  >
                                    {/* <img src={tick_green} alt="" /> */}
                                    Reject
                                  </Link>
                                </label>
                              </td>
                              <Modal
                                show={show}
                                onHide={handleClose}
                                animation={true}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                className="modal-content-box vehicle-requests-main-modal "
                              >
                                <Modal.Header
                                  onClick={() => {
                                    setModal(!modal);
                                  }}
                                  closeButton
                                ></Modal.Header>

                                <div>
                                  <div class="mb-3">
                                    <label for="comment" class="form-label">
                                      Comment
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      id="comment"
                                      onChange={(e) => {
                                        setComment(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="btn-popup-modal text-center mt-4">
                                  {!action ? (
                                    <button
                                      className="popup-approve-btn m-1 "
                                      onHide={handleClose}
                                      onClick={() =>
                                        shipperApprove(shipperList.user_id)
                                      }
                                      disabled={comment === "" ? true : false}
                                    >
                                      Accept
                                    </button>
                                  ) : (
                                    <button
                                      className="popup-reject-btn "
                                      onHide={handleClose}
                                      onClick={() =>
                                        shipperReject(shipperList.user_id)
                                      }
                                      disabled={comment === "" ? true : false}
                                    >
                                      Reject
                                    </button>
                                  )}
                                </div>
                              </Modal>
                            </tr>
                          );
                        })
                      ) : (
                        <div
                          className="text-danger"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          No Load Available
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
