import React, { useContext, useEffect, useState } from "react";
import ic_edit from "../../assets/images/ic_edit (2).svg";

import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import {
  postWithAuthCallWithErrorResponse,
  PostCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";

const VehicleApproval = () => {
  const navigate = useNavigate();

  const { customerData, setApiCalling } = useContext(AppContext);
  const [shipperPendingList, setShipperPendingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const getVehicleList = () => {
    var uri = ApiConfig.VEHICLE_ADD_APPROVAL_LIST;
    setApiCalling(true);
    postWithAuthCallWithErrorResponse(uri, JSON.stringify({ ...customerData }))
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setShipperPendingList(res.json.vehicle_list);
          setApiCalling(false);
        }
      })
      .catch((err) => {
        console.log("Ex :: ", err);
      });
  };

  const shipperApprove = (id, status) => {
    setApiCalling(true);

    PostCallWithErrorResponse(ApiConfig.VEHICLE_ADD_APPROVAL, {
      ...customerData,
      vehicle_id: id,
      status: status === "pending" ? "active" : "removed",
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          getVehicleList();
          // navigate("/VehicleList/");
          swal(res.json.message);
          setApiCalling(false);
        } else {
          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const shipperReject = (id) => {
    setApiCalling(true);

    PostCallWithErrorResponse(ApiConfig.REJECT_VEHICLE, {
      ...customerData,
      vehicle_id: id,
    })
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          swal(res.json.message);
          getVehicleList();
          setApiCalling(false);
        } else {
          swal(res.json.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getVehicleList();
  }, []);

  return (
    <React.Fragment>
      <main>
        <div style={{ margin: "20px" }}>
          <div className=" all-request">
            <div className="  ">
              <div className="offerLoadsMainLists">
                <div className="mainLoadTable" style={{ overflow: "auto" }}>
                  <table className="dataList" style={{ minWidth: "900px" }}>
                    <thead>
                      <tr>
                        <th>
                          <label>S.No</label>
                        </th>
                        <th>
                          <label>Owner Name</label>
                        </th>
                        <th>
                          <label>Plate Number</label>
                        </th>
                        <th>
                          <label>Vehicle Capacity</label>
                        </th>
                        <th>
                          <label>Vehicle Type</label>
                        </th>
                        <th>
                          <label>Vehicle Motor No.</label>
                        </th>{" "}
                        <th>
                          <label>Vehicle Status</label>
                        </th>
                        <th
                          className=" text-end "
                          style={{ paddingRight: "50px" }}
                        >
                          <label>Action</label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shipperPendingList ? (
                        shipperPendingList &&
                        shipperPendingList.map((vehicleList, index) => {
                          return (
                            <tr
                              className="Rowbgcolor"
                              key={"vehicleList" + index}
                            >
                              <td>
                                <label>{index + 1}</label>
                              </td>
                              <td>
                                <Link
                                  className="text-decoration-none"
                                  to={"/ShipperDetails/" + vehicleList.user_id}
                                  state={vehicleList}
                                >
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                      color: "#38847C",
                                      textDecoration: "underline",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {vehicleList.owner_name}
                                  </label>
                                </Link>
                              </td>
                              <td>
                                <label>{vehicleList.plate_number}</label>
                              </td>
                              <td>
                                <label>
                                  {vehicleList.vehicle_capacity} Quintals
                                </label>
                              </td>

                              <td>
                                <label>{vehicleList.vehicle_type}</label>
                              </td>
                              <td>
                                <label>{vehicleList.vehicle_motor_no}</label>
                              </td>
                              <td>
                                <label>{vehicleList.vehicle_status}</label>
                              </td>

                              <td className="text-end">
                                <label>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      shipperApprove(
                                        vehicleList.vehicle_id,
                                        vehicleList.vehicle_status
                                      )
                                    }
                                    className="regiBTN"
                                    id="accept"
                                  >
                                    Accept
                                    {/* <img src={cross_green} alt="" /> */}
                                  </Link>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      shipperReject(vehicleList.vehicle_id)
                                    }
                                    className="regiBTN"
                                    id="reject"
                                  >
                                    {/* <img src={tick_green} alt="" /> */}
                                    Reject
                                  </Link>
                                </label>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div
                          className="text-danger"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          No Load Available
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default VehicleApproval;
