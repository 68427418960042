import React, {useContext} from 'react';
import { Link } from "react-router-dom";
import { AppContext } from '../context/user/AppContext';
const PageNotFound = () => {
    const { mainClass } = useContext(AppContext);

    return (
        <>
            <main className={`main-body-Notification ${mainClass}`} id="cxMain" >

                <h3>Page Not Found</h3>
                <Link to="dashboard" >
                    <h6>Go to Home Page</h6>
                </Link>
            </main>
        </>
    
    );
}
export default PageNotFound;