import React, { useContext, useEffect, useState } from "react";
import user_1 from "../../assets/images/header/ChangeDriver/user_1.svg";
import user_2 from "../../assets/images/header/ChangeDriver/user_2.svg";
import user_3 from "../../assets/images/header/ChangeDriver/user_3.svg";
import user_4 from "../../assets/images/header/ChangeDriver/user_4.svg";
import { Link } from "react-router-dom";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";


const ChangeDriver = ({ changeDriver, transporter_id }) => {
  const [driverList, setDriverList] = useState([])
  const { customerData } = useContext(AppContext);
  const [selecedDriver, setSelecedDriver] = useState("")
  useEffect(() => {
    if (transporter_id)
      getDriverList();
  }, [transporter_id])
  const getDriverList = () => {
    postWithAuthCallWithErrorResponse(ApiConfig.DRIVERS_BY_TRSNSPORTER, JSON.stringify({ ...customerData, transporter_id }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        if (res.json.result) {
          setDriverList(res.json.driver_list)
        }
      })
  }
  return (
    <React.Fragment>
      <section>
        <div className="cx-account-out">
          <div className=" Account-content">
            <div className="Account-header">
              <div
                className="Account offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasDriverAccount"
                aria-labelledby="offcanvasDriverAccount"
              >
                <div className="offcanvas-header justify-content-between">
                  <h5 className="ms-4  card-heading">Change Driver</h5>

                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>

                <ul className="list-container">
                  {driverList.map((driver, index) => {
                    return (
                      <li className="list-unstyled mt-2" key={"driver" + index}>
                        <div className="icon_arrow border-bottom">
                          <Link to="#" className="text-decoration-none">
                            <div className="d-flex ms-2 promenu">
                              <img src={user_1} alt="" />
                              <p className=" change-driver-list m-2 ">{driver.driver_name}</p>
                            </div>
                          </Link>
                          <div className="form-check p-3">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios2"
                              value="option2"
                              onChange={(e) => setSelecedDriver(e.target.checked && driver.driver_id)}
                            />{" "}
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>

                <button
                  type="submit"
                  data-bs-dismiss="offcanvas"
                  className="btn m-3 mt-5 btn-change-driver"
                  onClick={() => { changeDriver(selecedDriver) }}
                >
                  Save
                </button>
              </div>
            </div>

          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ChangeDriver;
