import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import DriverDetails from "../Driver/DriverDetails";
import VehiclesDetails from "../Vehicles/VehiclesDetails";
// import '../../assets/css/VehicleDriverDetailsHeader.scss'
import $ from "jquery";
const VehicleDriverDetailsHeader = () => {
  const params = useParams();
  const [state, setState] = useState({
    ...params,
    driver: params.driver === "true" ? true : false,
  });
  const [loading, setLoading] = useState(false);
  const [VehicleDetails, setVehicleDetails] = useState({});
  const [owner_details, setOwner_details] = useState({});
  const [driver_details, setDriver_details] = useState([]);
  const { mainClassB, customerData } = useContext(AppContext);

  useEffect(() => {
    getVehicleDetails();
    // $('#rowTab a:first').tab('show');
  }, []);
  const getVehicleDetails = () => {
    setLoading(true);
    if (state.driver) {
      postWithAuthCallWithErrorResponse(
        ApiConfig.DRIVER_DETAILS,
        JSON.stringify({ ...customerData, driver_id: state.id })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setLoading(false);
          setVehicleDetails(res.json.vehicle_details);
          setDriver_details(res.json.driver_details);
          setOwner_details(res.json.owner_details);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      postWithAuthCallWithErrorResponse(
        ApiConfig.VEHICLE_DETAILS,
        JSON.stringify({ ...customerData, vehicle_id: state.id })
      )
        .then((res) => {
          if (res.json.message === 
            "Invalid user authentication,Please try to relogin with exact credentials.") {
              localStorage.clear();
              window.open("/login", "_self");
          }
          setLoading(false);
          setVehicleDetails(res.json.vehicle_details);
          setDriver_details(res.json.driver_details);
          setOwner_details(res.json.owner_details);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <main className=" ">
        <div
          className={`vehicle-driver-detail-header scroll-style ${mainClassB} `}
          id="cxMainB"
        >
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <>
              <ul
                className="nav nav-pills mb-1 pt-2"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className={`nav-link tab-link ${state.driver && "active"}`}
                    id="pills-customerdetails-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-customerdetails"
                    type="button"
                    role="tab"
                    aria-controls="pills-customerdetails"
                    aria-selected="false"
                  >
                    Driver Details
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className={`nav-link tab-link ${!state.driver && "active"}`}
                    id="pills-driverdetails-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-driverdetails"
                    type="button"
                    role="tab"
                    aria-controls="pills-driverdetails"
                    aria-selected="true"
                  >
                    Vehicle Details
                  </Link>
                </li>
              </ul>
              <div
                className="tab-content active"
                id="pills-tabContent"
                style={{ marginBottom: "80px", overflow: "auto" }}
              >
                <div
                  className={`tab-pane fade show ${!state.driver && "active"}`}
                  id="pills-driverdetails"
                  role="tabpanel"
                  aria-labelledby="pills-driverdetails-tab"
                >
                  {VehicleDetails?.vehicle_id && (
                    <VehiclesDetails
                      getVehicleDetails={getVehicleDetails}
                      vehicleDeatails={VehicleDetails}
                      ownerDetails={owner_details}
                    />
                  )}
                </div>

                <div
                  className={`tab-pane fade show ${state.driver && "active"}`}
                  id="pills-customerdetails"
                  role="tabpanel"
                  aria-labelledby="pills-customerdetails-tab"
                >
                  {(driver_details.driver_id || driver_details.user_id) && (
                    <DriverDetails
                      driverDetails={driver_details}
                      getVehicleDetails={getVehicleDetails}
                      ownerDetails={owner_details}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default VehicleDriverDetailsHeader;
