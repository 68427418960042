import React, { useContext, useEffect, useState } from "react";
import ic_trucks from "../../assets/images/Skyscrapers.jpg";
import ic_options from "../../assets/images/megaoffer/ic_options.svg";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import ic_dropdown_arrow from "../../assets/images/dropdown_arrow_blue.svg";

const AllRequests = ({ all, searchText }) => {
  const { customerData } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [AllRequests, setAllRequests] = useState([]);
  const [orginalList, setOrginalList] = useState([]);
  const [orderShipper, setOrderShipper] = useState([]);
  useEffect(() => {
    let list = orginalList.filter((data) =>
      data.load_reference_no.includes(searchText)
    );
    setAllRequests(list);
  }, [searchText]);

  useEffect(() => {
    var newArray = AllRequests.filter(function (el) {
      return el.trip_status === "Order Sent to Shipper";
    });
    setOrderShipper(newArray);
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    getAllRequests();
  }, []);
  const getAllRequests = () => {
    setLoading(true);
    postWithAuthCallWithErrorResponse(
      all
        ? ApiConfig.DRIRECT_ORDER_ALLREQUEST
        : ApiConfig.DIRECT_ORDER_MYREQUEST,
      JSON.stringify({ ...customerData })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false);
        if (res.json.result) {
          setAllRequests(res.json.load_list);
          setOrginalList(res.json.load_list);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <div style={{ margin: "20px" }}>
          <div className=" all-request">
            <div className="  ">
              <div className="offerLoadsMainLists">
                <div className="mainLoadTable">
                  <table className="dataList" style={{ minWidth: "1100px" }}>
                    <thead>
                      <tr>
                        <th>
                          <label>S.No</label>
                        </th>
                        <th>
                          <label>Reference No.</label>
                        </th>
                        <th>
                          <label>From-To</label>
                        </th>
                        <th>
                          <label>Offered By</label>
                        </th>
                        <th>
                          <label>Cargo Type</label>
                        </th>
                        <th>
                          <label>Quantity</label>
                        </th>
                        <th>
                          <label>Est.Start.Dt</label>
                        </th>
                        <th>
                          <label>Status</label>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {AllRequests.length > 0 ? (
                        AllRequests.map((request, index) => {
                          return (
                            <tr className="Rowbgcolor">
                              <td>
                                <label>{index + 1}</label>
                              </td>
                              <td>
                                <label>
                                  <img
                                    src={
                                      ApiConfig.BASE_URL_FOR_IMAGES +
                                        request.trip_image || ic_trucks
                                    }
                                    alt=""
                                    className="directLoad-img card-rsponsive-img vehicle-request-img"
                                    style={{
                                      height: "22px",
                                      width: "22px",
                                      marginRight: "7px",
                                    }}
                                  />
                                  {request.load_reference_no}
                                </label>
                              </td>
                              <td>
                                <label>
                                  {request.trip_start_city}{" "}
                                  {request.trip_start_country} -{" "}
                                  {request.trip_end_city}{" "}
                                  {request.trip_end_country}
                                </label>
                              </td>
                              <td>
                                <label>{request.offered_by}</label>
                              </td>
                              <td>
                                <label>{request.cargo_type}</label>
                              </td>
                              <td>
                                <label>
                                  {request.quantity} {request.unit}.
                                </label>
                              </td>
                              <td>
                                <label>{request.estimated_start_date}</label>
                              </td>
                              <td>
                                <label>
                                  {request.trip_status ===
                                    "Transporters Assigned" && (
                                    <>
                                      <Link to="#">
                                        <button
                                          type="button"
                                          className="  text-center TransportersAssigned-btn"
                                        >
                                          Transporter’s Assigned
                                        </button>
                                      </Link>{" "}
                                      <br />
                                    </>
                                  )}
                                  {request.trip_status ===
                                    "Order Sent to Shipper" && (
                                    <>
                                      <Link to="#">
                                        <button
                                          type="button"
                                          className="  text-center TransportersAssigned-btn"
                                        >
                                          {request.trip_status}
                                        </button>
                                      </Link>{" "}
                                      <br />
                                    </>
                                  )}
                                  {request.trip_status ===
                                      "shipper_accepted" && (
                                      <>
                                        <Link to="#">
                                          <button
                                            type="button"
                                            className="  text-center TransportersAssigned-btn"
                                          >
                                            Shipper has approved the offer
                                          </button>
                                        </Link>
                                        <br />

                                        <br />
                                        <Link
                                          to="#"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            localStorage.setItem(
                                              "request",
                                              JSON.stringify(request)
                                            );
                                            navigate("/MatchVechicleWithCargo");
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="green-link-btn "
                                          >
                                            Assign Vehicles to Cargo
                                          </button>
                                        </Link>
                                      </>
                                    )}
                                  {request.trip_status ===
                                    "shipper_rejected" && (
                                    <>
                                      <Link to="#">
                                        <button
                                          type="button"
                                          className="  text-center TransportersAssigned-btn"
                                        >
                                          Shipper has Rejected the Offer
                                        </button>
                                      </Link>{" "}
                                      <br />
                                      <Link
                                        to="#"
                                        nClick={(e) => {
                                          e.preventDefault();
                                          localStorage.setItem(
                                            "request",
                                            JSON.stringify(request)
                                          );
                                          navigate("/AddVehicleSpecification");
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="green-link-btn "
                                        >
                                          Reassign Transporter
                                        </button>
                                      </Link>
                                    </>
                                  )}
                                  {request.trip_status === "match_made" && (
                                    <>
                                      <Link to="#">
                                        <button
                                          type="button"
                                          className="  text-center TransportersAssigned-btn"
                                        >
                                          Matching Making has been completed
                                        </button>
                                      </Link>{" "}
                                      <br />
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          localStorage.setItem(
                                            "request",
                                            JSON.stringify(request)
                                          );
                                          navigate(
                                            "/OrderConfirmationForTransporter"
                                          );
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="green-link-btn "
                                        >
                                          Send Order Confirmation
                                        </button>
                                      </Link>
                                    </>
                                  )}
                                  {request.trip_status ===
                                    "Transporters Responded" && (
                                    <>
                                      <Link to="#">
                                        <button
                                          type="button"
                                          className="  text-center TransportersAssigned-btn"
                                        >
                                          Transporter’s Have Responded
                                        </button>
                                      </Link>{" "}
                                      <br />
                                      <Link
                                        to="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          localStorage.setItem(
                                            "request",
                                            JSON.stringify(request)
                                          );
                                          navigate("/TransporterResponse");
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="green-link-btn "
                                        >
                                          View Response
                                        </button>
                                      </Link>
                                    </>
                                  )}
                                  {request.trip_status === "assigned" && (
                                    <>
                                      <Link to="#">
                                        <button
                                          type="button"
                                          className="  text-center TransportersAssigned-btn"
                                        >
                                          {request.trip_status}
                                        </button>
                                      </Link>{" "}
                                      <br />
                                    </>
                                  )}
                                </label>
                              </td>
                              <td>
                                <label>
                                  <div className="option-dropdown dropdown ">
                                    <Link
                                      to="#"
                                      href=""
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img src={ic_options} alt="" />
                                    </Link>
                                    <ul className="dropdown-menu ">
                                      <li>
                                        <Link
                                          to={"/ViewDetails/" + request.trip_id}
                                          className="text-decoration-none"
                                        >
                                          <button
                                            className="dropdown-item"
                                            type="button"
                                          >
                                            View Details
                                            <img
                                              src={ic_dropdown_arrow}
                                              className="arrowvehicle"
                                              alt=""
                                            />
                                          </button>
                                        </Link>
                                      </li>
                                      <li>
                                        <button
                                          className="dropdown-item mt-2"
                                          type="button"
                                          to="#offcanvasDriverAccount"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasFrightStatus"
                                          aria-controls="offcanvasRightAccount"
                                        >
                                          View Transporter Response
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </label>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div
                          className="text-danger"
                          style={{
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          No Load Available
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AllRequests;
