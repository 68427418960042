import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CompleteFreights from "./CompleteFreights";
import OngoingFreights from "./OngoingFreights";
import UpcomingFreights from "./UpcomingFreights";
import "../../assets/css/Freightsheader.scss";
import { AppContext } from "../../context/user/AppContext";
import Loader from "../../sharedComponent/Loader";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

// import '../../assets/css/VehicleDriverDetailsHeader.scss'

const FreightsHeaders = () => {
  const [loading, setLoading] = useState(false)
  const { mainClassB, customerData } = useContext(AppContext);
  const [ongoingFrights, setongoingFrights] = useState([])
  const [upCommingFrights, setUpCommingFrights] = useState([])
  const [completedFrights, setCompletedFrights] = useState([])
  // useState({ ongoingFrights: [], upCommingFrights: [], CompletedFrights: [] })
  useEffect(() => {
    getOngoingFrights();
    getUpCommingFrights();
    getCompletedFrights();
  }, [])
  const getOngoingFrights = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(ApiConfig.ONGOING_FRIGHTS, JSON.stringify({ ...customerData }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false)
        if (res.json.result) {
          setongoingFrights(res.json.load_list)
        }
      }).catch(err => {
        setLoading(false)
        console.log(err);
      })
  }
  const getUpCommingFrights = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(ApiConfig.UPCOMMING_FRIGHTS, JSON.stringify({ ...customerData }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false)
        if (res.json.result) {
          setUpCommingFrights(res.json.load_list)
        }
      }).catch(err => {
        setLoading(false)
        console.log(err);
      })
  }
  const getCompletedFrights = () => {
    setLoading(true)
    postWithAuthCallWithErrorResponse(ApiConfig.COMPLETED_FRIGHTS, JSON.stringify({ ...customerData }))
      .then(res => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        setLoading(false)
        if (res.json.result) {
          setCompletedFrights(res.json.load_list)
        }
      }).catch(err => {
        setLoading(false)
        console.log(err);
      })
  }
  return (
    <main>
      <div className={`Freights-body ${mainClassB}`} id="cxMainB">
        <ul
          className="nav nav-pills mb-1 "
          id="pills-tab"
          role="tablist"

        >
          <li className="nav-item" role="presentation">
            <Link
              to="/OngoingFreights"
              className="nav-link tab-link active"
              id="pills-OngoingFreights-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-OngoingFreights"
              type="button"
              role="tab"
              aria-controls="pills-OngoingFreights"
              aria-selected=""
            >
              Ongoing Freights
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              to="/UpcomingFreights"
              className="nav-link tab-link"
              id="pills-UpcomingFreights-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-UpcomingFreights"
              type="button"
              role="tab"
              aria-controls="pills-UpcomingFreights"
              aria-selected=""
            >
              Upcoming Freights
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              to="/CompleteFreights"
              className="nav-link tab-link"
              id="pills-Completed-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-Completed"
              type="button"
              role="tab"
              aria-controls="pills-Completed"
              aria-selected=""
            >
              Completed
            </Link>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          {loading ?
            <Loader loading={loading} />
            :
            <>
              <div
                className="tab-pane fade show active"
                id="pills-OngoingFreights"
                role="tabpanel"
                aria-labelledby="pills-OngoingFreights-tab"
              >
                <OngoingFreights frights={ongoingFrights} />
              </div>

              <div
                className="tab-pane fade"
                id="pills-UpcomingFreights"
                role="tabpanel"
                aria-labelledby="pills-UpcomingFreights-tab"
              >
                <UpcomingFreights frights={upCommingFrights} />
              </div>
              <div
                className="tab-pane fade"
                id="pills-Completed"
                role="tabpanel"
                aria-labelledby="pills-Completed-tab"
              >
                <CompleteFreights frights={completedFrights} />
              </div>

              <div
                className="tab-pane fade show active"
                id="pills-Completed"
                role="tabpanel"
                aria-labelledby="pills-Completed-tab"
              ></div>
            </>
          }

        </div>
      </div>
    </main>
  );
};

export default FreightsHeaders;
