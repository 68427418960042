import React, { useContext } from "react";
import ic_options_menu from "../../assets/images/Trasnvehicle/ic_options.svg";
import document_image from "../../assets/images/vehiclesDetails/document_image.png";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import { Link } from "react-router-dom";
import ChangeDriver from "../Transporter/ChangeDriver";
import { AppContext } from "../../context/user/AppContext";
import ApiConfig from "../../api/ApiConfig";
import swal from "sweetalert";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import OwnerDetails from "./OwnerDetails";
const VehiclesDetails = ({
  vehicleDeatails,
  ownerDetails,
  getVehicleDetails,
}) => {
  const { customerData, mainClass } = useContext(AppContext);
  const blockvehicle = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_VEHICLE,
      JSON.stringify({
        ...customerData,
        vehicle_id: vehicleDeatails.vehicle_id,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        swal(res.json.message);
        getVehicleDetails();
      }
    });
  };
  const unBlockvehicle = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.UNBLOCK_VEHICLE,
      JSON.stringify({
        ...customerData,
        vehicle_id: vehicleDeatails.vehicle_id,
      })
    ).then((res) => {
      if (res.json.message === 
        "Invalid user authentication,Please try to relogin with exact credentials.") {
          localStorage.clear();
          window.open("/login", "_self");
      }
      if (res.json.result) {
        swal(res.json.message);
        getVehicleDetails();
      }
    });
  };
  const changeDriver = (id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.CHANGE_DRIVER,
      JSON.stringify({
        ...customerData,
        vehicle_id: vehicleDeatails.vehicle_id,
        driver_id: id,
      })
    )
      .then((res) => {
        if (res.json.message === 
          "Invalid user authentication,Please try to relogin with exact credentials.") {
            localStorage.clear();
            window.open("/login", "_self");
        }
        swal(res.json.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg";
    link.click();
  };
  return (
    <>
      <main className={`main-section-DriverDetails `}>
        <div className="main-body-DriverDetails mb-4">
          {/* vehicles details */}
          <section className="vehicle-detail-section mb-0 common-body p-3 mt-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="image">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {vehicleDeatails &&
                        vehicleDeatails.images &&
                        vehicleDeatails.images.map((img, index) => {
                          return (
                            <div
                              className={
                                index === 0
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                              key={img.vehicle_image_id}
                            >
                              <img
                                src={
                                  ApiConfig.BASE_URL_FOR_IMAGES +
                                  img.vehicle_image_url
                                }
                                className="d-block "
                                alt={"..."}
                                width="200px"
                                height={"200px"}
                              />
                            </div>
                          );
                        })}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right-section">
                  <div className="right-top-content d-flex justify-content-between">
                    <div className="title-vehicle">
                      <h4 className="name-vehicle">
                        {vehicleDeatails && vehicleDeatails.vehicle_name}
                      </h4>
                    </div>
                    <div
                      className="option-dropdown dropdown "
                      id="vehicleDetail-dropdown"
                    >
                      <a
                        href=""
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={ic_options_menu} alt="" />
                      </a>
                      <ul className="dropdown-menu ">
                        <li>
                          <Link
                            to={"/AddVehicles/" + vehicleDeatails.vehicle_id}
                            className="text-decoration-none"
                          >
                            <button className="dropdown-item" type="button">
                              Edit
                              <img
                                src={dropdown_arrow_blue}
                                className="arrowvehicle"
                                alt=""
                              />
                            </button>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className="text-decoration-none"
                            aria-current="page"
                            href="#"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasDriverAccount"
                            aria-controls="offcanvasDriverAccount"
                          >
                            <button className="dropdown-item" type="button">
                              Assign Driver
                            </button>
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="#" className="text-decoration-none">
                            <button className="dropdown-item" type="button">
                              Vehicle Details
                            </button>
                          </Link>
                        </li> */}

                        {/* <li>
                          <Link
                            to="/ContractTransporter"
                            className="text-decoration-none"
                          >
                            <button className="dropdown-item" type="button">
                              View Contract
                            </button>
                          </Link>
                        </li> */}
                        <li>
                          <button className="dropdown-item" type="button">
                            Call
                          </button>
                        </li>

                        {vehicleDeatails.vehicle_status === "blocked" ? (
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={unBlockvehicle}
                            >
                              Unblock
                            </button>
                          </li>
                        ) : (
                          <li>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={blockvehicle}
                            >
                              Block
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="bottom-content row mt-4">
                    <div className="col-lg-4 col-sm-6 left item">
                      <label>Plate Number</label>
                      <p className="data">
                        {vehicleDeatails && vehicleDeatails.plate_number}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 middel item">
                      <label>Vehicle Type</label>
                      <p className="data">
                        {vehicleDeatails && vehicleDeatails.vehicle_name}
                      </p>
                    </div>
                    <div className=" col-lg-4 col-sm-6 right item">
                      <label>Year of Manufacture</label>
                      <p className="data">
                        {vehicleDeatails && vehicleDeatails.year_manufacture}
                      </p>
                    </div>
                  </div>
                  <div className="bottom-content row mt-3">
                    <div className="col-lg-4 col-sm-6 left item">
                      <label>Chassis Number</label>
                      <p className="data">
                        {vehicleDeatails && vehicleDeatails.vehicle_chassis_no}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 middel item">
                      <label>Motor Number</label>
                      <p className="data">
                        {vehicleDeatails && vehicleDeatails.vehicle_motor_no}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 right item">
                      <label>Gross Weight</label>
                      <p className="data">
                        {vehicleDeatails &&
                          vehicleDeatails.vehicle_gross_weight}
                      </p>
                    </div>
                  </div>
                  <div className="bottom-content row mt-3">
                    <div className="col-lg-4  col-sm-6 left item">
                      <label>Vehicle Load Capacity</label>
                      <p className="data">
                        {vehicleDeatails && vehicleDeatails.vehicle_capacity}{" "}
                        Quintals
                      </p>
                    </div>

                    <div className="col-lg-4 right item col-sm-6">
                      <label>Initial km</label>
                      <p className="data">
                        {vehicleDeatails && vehicleDeatails.vehicle_initial_km}
                      </p>
                    </div>
                  </div>
                  <h5 className="card-heading">GPS Availability</h5>
                  <div className="bottom-content row mt-3">
                    <div className="col-lg-4  col-sm-6 left item">
                      <label>Vendor Name</label>
                      <p className="data">
                        {vehicleDeatails && vehicleDeatails.vehicle_vendor_name}
                      </p>
                    </div>

                    <div className="col-lg-4 col-sm-6 right item">
                      <label>Vendor Contact</label>
                      <p className="data">
                        {vehicleDeatails &&
                          vehicleDeatails.vehicle_vendor_contact}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 right item">
                      <label>Vendor Platform</label>
                      <p className="data">
                        {vehicleDeatails &&
                          vehicleDeatails.vehicle_vendor_platform}
                      </p>
                    </div>
                    <div className="col-lg-4 col-sm-6 right item">
                      <label>Vendor Address</label>
                      <p className="data">
                        {vehicleDeatails &&
                          vehicleDeatails.vehicle_vendor_address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* vehicles documents details */}
          <section className="documents-detail-section common-body p-3 mt-4">
            <div className="row">
              <div className="col-12">
                <div className="top-content">
                  <h5 className="card-heading">Documents</h5>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-lg-2 col-sm-6 ">
                    <div className="insurance-copy">
                      <label>Insurance Copy</label>
                      <div className="d-flex mx-1">
                        <img
                          src={
                            vehicleDeatails
                              ? ApiConfig.BASE_URL_FOR_IMAGES +
                                vehicleDeatails.vehicle_insurance_image
                              : document_image
                          }
                          alt=""
                          width={120}
                          height={120}
                        />
                        <div className="option-dropdown dropdown ">
                          <a
                            href=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={ic_options_menu}
                              className="dot-icon"
                              alt=""
                            />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button
                                onClick={() => {
                                  handleDownload(
                                    ApiConfig.BASE_URL_FOR_IMAGES +
                                      vehicleDeatails.vehicle_insurance_image
                                  );
                                }}
                                className="dropdown-item"
                                type="button"
                              >
                                Download
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>Issue Date</label>
                    <p className="data">
                      {vehicleDeatails &&
                        vehicleDeatails.vehicle_insurance_issue_date}
                    </p>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>Expiry Date</label>
                    <p className="data">
                      {vehicleDeatails &&
                        vehicleDeatails.vehicle_insurance_expiry}
                    </p>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>Insurance Company</label>
                    <p className="data">
                      {vehicleDeatails &&
                        vehicleDeatails.vehicle_insurance_company}
                    </p>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>Insurance Type</label>
                    <p className="data">
                      {vehicleDeatails && vehicleDeatails.insurance_type}
                    </p>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>Sum Insuranced</label>
                    <p className="data">
                      ${vehicleDeatails && vehicleDeatails.vehicle_sum_insured}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <OwnerDetails ownerDetails={ownerDetails} />
        </div>
      </main>
      <ChangeDriver
        changeDriver={changeDriver}
        transporter_id={ownerDetails && ownerDetails.user_id}
      />
    </>
  );
};

export default VehiclesDetails;
