import React, { useContext } from "react";
import document_image from "../../assets/images/vehiclesDetails/document_image.png";
import star_highlighted from "../../assets/images/vehiclesDetails/star_highlighted.svg";
import star_grey from "../../assets/images/vehiclesDetails/ic_star_grey.svg";
import ic_options_menu from "../../assets/images/Trasnvehicle/ic_options.svg";
import { Link } from "react-router-dom";
import user_1 from "../../assets/images/Dashboard/user_1.svg";
import dropdown_arrow_blue from "../../assets/images/dropdown_arrow_blue.svg";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import swal from "sweetalert";
import { AppContext } from "../../context/user/AppContext";
import OwnerDetails from "../Vehicles/OwnerDetails";

const OfferLoadDriverDetails = ({ driverDetails, getVehicleDetails, ownerDetails }) => {
  let id = "";
  if (driverDetails)
    id = driverDetails.driver_id
      ? driverDetails.driver_id
      : driverDetails.user_id;
  const { customerData } = useContext(AppContext);
  
  const handleDownload = (imageUrl) => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = "image.jpg";
    link.click();
  };
  return (
    <>
      <main className={`main-section-DriverDetails `}>
        <div className="main-body-DriverDetails">
          {/* Driver Details */}
          <section className="owner-detail-section common-body p-3 mt-4">
            <div className="row">
              <div className="col-12">
                <div className="top-content d-flex justify-content-between">
                  <div className="driver-profile-details d-flex align-items-center">
                    <div className="profile-pic me-3">
                      <img
                        src={
                          driverDetails && driverDetails.profile_pic
                            ? ApiConfig.BASE_URL_FOR_IMAGES +
                              driverDetails.profile_pic
                            : user_1
                        }
                        alt=""
                      />
                    </div>
                    <div className="driver-name">
                      <div className="title-driver">
                        <h4 className="name-driver">
                          {(driverDetails && driverDetails.driver_name) ||
                            driverDetails.driver_name}
                        </h4>
                      </div>
                      <div className="rating">
                        <img src={star_highlighted} alt="" />
                        <img src={star_highlighted} alt="" />

                        <img src={star_highlighted} alt="" />
                        <img src={star_highlighted} alt="" />
                        <img src={star_grey} alt="" />
                        <span>(320)</span>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="row mt-4">
                  <div className="col-lg-3 col-sm-6 ">
                    <label>Full Name</label>
                    <p className="data">
                      {(driverDetails && driverDetails.driver_name) ||
                        driverDetails.user_name}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-6 ">
                    <label>Email</label>
                    <p className="data">
                      {(driverDetails && driverDetails.email) ||
                        driverDetails.user_email}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-6 ">
                    <label>Phone Number</label>
                    <p className="data">
                      {(driverDetails && driverDetails.mobile_number) ||
                        driverDetails.user_mobile}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-6 ">
                    <label>Mobile Number</label>
                    <p className="data">
                      {(driverDetails && driverDetails.mobile_number) ||
                        driverDetails.user_mobile}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-6 ">
                    <label>Zone</label>
                    <p className="data">
                      {(driverDetails && driverDetails.zone) ||
                        driverDetails.user_zone}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-6  ">
                    <label>Woreda</label>
                    <p className="data">
                      {(driverDetails && driverDetails.woreda) ||
                        driverDetails.user_woreda}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-6  ">
                    <label>House No.</label>
                    <p className="data">
                      {(driverDetails && driverDetails.house_no) ||
                        driverDetails.user_house_no}
                    </p>
                  </div>
                  <div className="col-lg-3 col-sm-6 ">
                    <label>P.O Box</label>
                    <p className="data">
                      {(driverDetails && driverDetails.po_box) ||
                        driverDetails.user_po_number}
                    </p>
                  </div>
                  {/* <div className="col-lg-3 col-sm-6  ">
                    <label>Birthdate</label>
                    <p className="data">{driverDetails && driverDetails.birthdate || driverDetails.user_dob}</p>
                  </div>
                  <div className="col-lg-3 col-sm-6 ">
                    <label>Gender</label>
                    <p className="data">{driverDetails && driverDetails.gender || driverDetails.user_gender}</p>
                  </div> */}
                  {/* <div className="col-lg-6 col-md-6 mt-4 ">
                  <label>Address</label>
                  <p className="data">No.2/28H, Near Majid, Sainiketan Colony, Kalas Area</p>
                </div> */}
                </div>
              </div>
            </div>
          </section>
          {/* Documents dtails */}
          <section className="documents-detail-section common-body p-3 mt-4 mb-4">
            <div className="row">
              <div className="col-12">
                <div className="top-content">
                  <h5 className="card-heading">Documents</h5>
                  <hr />
                </div>
                <div className="row mt-4">
                  <div className="col-lg-2 col-sm-6 ">
                    <div className="insurance-copy">
                      <label>Licence Copy</label>
                      <div className="d-flex mx-1">
                        <img
                          src={
                            driverDetails
                              ? ApiConfig.BASE_URL_FOR_IMAGES +
                                driverDetails.license_document
                              : document_image
                          }
                          alt=""
                          width={120}
                          height={120}
                        />
                        <div className="option-dropdown dropdown ">
                          <a
                            href=""
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={ic_options_menu}
                              className="dot-icon"
                              alt=""
                            />
                          </a>
                          <ul className="dropdown-menu ">
                            <li>
                              <button
                                onClick={() => {
                                  handleDownload(
                                    ApiConfig.BASE_URL_FOR_IMAGES +
                                      driverDetails.license_document
                                  );
                                }}
                                className="dropdown-item"
                                type="button"
                              >
                                Download
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>Issue Date</label>
                    <p className="data">
                      {driverDetails.license_issue_date ||
                        driverDetails.driver_license_issue_date}
                    </p>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>Expiry Date</label>
                    <p className="data">
                      {driverDetails.license_expiry_date ||
                        driverDetails.driver_license_expiry_date}
                    </p>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>License Grade</label>
                    <p className="data">
                      {driverDetails.license_grade ||
                        driverDetails.driver_license_grade}
                    </p>
                  </div>
                  <div className="col-lg-2 col-sm-6 ">
                    <label>License Number</label>
                    <p className="data">
                      {driverDetails.licence_number ||
                        driverDetails.licence_number}
                    </p>
                  </div>
                  {/* <div className="col-lg-2 col-md-6 mt-4 ">
                  <label>Sum Insuranced</label>
                  <p className="data">$200</p>
                </div> */}
                </div>
              </div>
            </div>
          </section>
          <OwnerDetails ownerDetails={ownerDetails} />
        </div>
      </main>
    </>
  );
};

export default OfferLoadDriverDetails;
